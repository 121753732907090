@import url("https://fonts.cdnfonts.com/css/myriad-pro");
@import url("./protokoll/ProtokollObjectOwner.css");
@import url("./protokoll/ProtokolSteps.css");
@import url("./protokoll/ProtokollType.css");
@import url("./authentication/ForgetPassword.css");
@import url("./authentication/ForgetPassword.css");
@import url("./authentication/Login.css");
@import url("./Side.css");
/* @import url('./basket/Sidebar.css'); */
@import url("./Dashboard.css");
@import url("./protokoll/ProtokollOvwerviewRooms.css");
@import url("./protokoll/Roomdetails.css");
@import url("./protokoll/ProtokollRenter.css");
@import url("./protokoll/ProtkollCounter.css");
@import url("./protokoll/ZahlertypList.css");
@import url("./protokoll/ProtokollKeys.css");
@import url("./protokoll/ProtokollOvwerview.css");


/* #### Generate font-face Myriad Pro #### */

@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/MYRIADPRO-BOLD.woff") format("woff");
}

:root {
  --thm-font: "Myriad Pro Regular", sans-serif;
  --thm-font-bold: "Myriad Pro Regular", sans-serif;
  --heading-font: "Myriad Pro Regular", sans-serif;
  --primary: #62ab12 !important;
  --primary-rgb: 254, 0, 64;
  --black: #182c44;
  --black-rgb: 24, 44, 68;
  --text: #888888;
  --white: #fff;
  --black-bg: #0a1829;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 16px !important;

}

.bg-color-home {
  font-size: 16px;
  line-height: 1.87;
  color: var(--text);
  font-family: var(--thm-font);
  background-image: url("./../Icons/gruppe-494@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #00447b;
  /* 100% of viewport height */
  width: 100%;
  height: 100%;
  /* 100% of viewport width */
}

.bg-color-home1 {
  font-size: 16px;
  line-height: 1.87;
  color: var(--text);
  font-family: var(--thm-font);
  width: 100%;
  height: 100%;
}


/* Webkit scrollbar styles */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
  cursor: pointer;
}
*::-webkit-scrollbar-thumb {
  display: none;
}
/* Firefox and Edge scrollbar styles */
body {
  overflow: initial !important;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

body::-webkit-scrollbar {
  width: 7px;
}

.forgetPass:hover {
  color: #0056b3 !important
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
}

.page-wrapper {
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

p {
  line-height: 32px;
  font-size: 18px;
  hyphens: auto;
}

.btn,
.btn:focus,
button,
button:focus {
  outline: none !important;
}

.btn {
  font-size: 0.9rem;
  padding: 0.7rem 1.4rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 7px;
}

.btn:hover {
  outline: none;
  text-decoration: none;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-30 {
  font-size: 30px;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

/*------------------------------------------------
--------------------------
3. Upper Header
--------------------------
------------------------------------------------*/
.layout-container {
  display: flex;
  flex-direction: row;
}

.menu-sidebar {
  flex: 1;
  margin-left: 20%;
}

.margin-navbar {
  margin: 0px;
}

.upper-header {
  background-color: #00457c;
  width: 100%;
}

@media (min-width: 768px) {
  .upper-header {
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .upper-header .container {
    display: flex;
    justify-content: flex-end;
  }
}

.upper-header .side-menu-toggler {
  font-size: 20px;
  cursor: pointer;
  color: var(--black);
}

@media (min-width: 768px) {
  .upper-header .side-menu-toggler {
    margin-left: 15px;
  }
}

@media (min-width: 1200px) {
  .upper-header .side-menu-toggler {
    display: none;
  }
}

.upper-header .header-infos {
  display: none;
}

@media (min-width: 768px) {
  .upper-header .header-infos {
    display: flex;
    align-items: center;
  }
}

.upper-header .header-info {
  padding-left: 41px;
  position: relative;
}

.upper-header .header-info+.header-info {
  margin-left: 30px;
}

@media (min-width: 992px) {
  .upper-header .header-info+.header-info {
    margin-left: 50px;
  }
}

.upper-header .header-info>i {
  font-size: 28px;
  color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
}

.upper-header .header-info h3 {
  margin: 0;
  color: var(--black);
  font-size: 16px;
  font-weight: bold;
  font-family: var(--thm-font);
  margin-bottom: 3px;
}

.upper-header .header-info p {
  margin: 0;
  color: var(--black);
  font-size: 14px;
  font-family: var(--thm-font);
}

.upper-header .header-info h3 a {
  color: inherit;
  transition: 500ms;
}

.upper-header .header-info h3 a:hover {
  color: var(--primary);
}

/* preloader */

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* scroll to top */

.scroll-to-top {
  width: 45px;
  height: 45px;
  background: #009fe3;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 99;
  text-align: center;
  transition: all 0.4s ease;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease;
}

.scroll-to-top i {
  color: #ffffff;
  font-size: 30px;
  line-height: 45px;
}

.scroll-to-top:hover {
  background-color: #333;
}

.scroll-to-top:hover i {
  color: #fff;
}

.loader__centred {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  height: 200px;
  width: 100%;

}

.checkbox-champ {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.checkbox-champ .hauptmieterH3 {
  margin-bottom: 0px !important;
}

.wohnungsdaten {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.sub_wohnungsdaten-border {
  border-top-left-radius: 5px;

}

.h-auto {
  height: auto !important;
}

#keys .wohnungsdaten h3 {
  font-size: 14px !important;
}

.neuer svg {
  width: 27px !important;
  height: 30px !important;
}

.sub_wohnungsdaten svg {
  width: 27px !important;
  height: 30px !important;
}

.hauptmieterH3 {
  font-size: 16px;
}

.checkbox-champ-first {
  padding: 10px 30px;
}

.OvwerviewGlobalButtonSUP button {
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  height: 63px;
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 1);
  opacity: 1;
  background-color: rgba(98, 171, 18, 1);
  margin-top: 10px;
  margin-bottom: 20px;
  color: rgba(255, 255, 255, 1);
  font-family: "Myriad Pro", sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.textfieldsdateplzObOwBgnone {
  display: flex;
  padding: 5px 20px;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}

.MuiDataGrid-footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-container img {
  width: 40px !important;
  object-fit: cover;
  height: 40px !important;
}

.text-danger {
  font-size: 14px !important;
}

.swal-button {
  background-color: rgb(98, 171, 18) !important;
}

.swal-footer {
  margin-bottom: 26px !important;
} 
.custom-swal-button {
  background-color: #7cd1f9 !important;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}
.swal-text {
  margin-bottom: 13px !important;
}

.column-content {

  cursor: pointer;
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  letter-spacing: 0px;
  text-align: left;
  line-height: 26px;
}

.icon-strom {

  filter: none !important;
}

.item-tickett {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.item-ticket-customer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.item-ticket-customer .MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(255, 255, 255);
}
.item-ticket-customer .MuiCheckbox-root {
  color: #fff !important;
}
.item-ticket-customer .toggle .slider::before {
  content: '';
  margin-left: 4px;
  width: 20px;
  height: 20px;
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
  transition: all 0.4s ease-in-out;
}
.item-ticket-customer .toggle input:checked + .slider {
  background-color: #62AB12;
}
.log-tickets {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.randomCodePromo {
  color: #00447b;
  cursor: pointer;
}

.search-contain {
  display: flex;
  justify-content: end;
}

.filter-box {
  padding: 10px;
  background: rgb(0, 68, 123);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.filter-box span {
  color: #fff
}

.filter-box .MuiGrid-root {
  text-align: center;
}

.filter-box label+.MuiInput-formControl {
  margin: inherit !important;
}

.filter-box label {
  color: #333;
}

.filter-box .MuiInputBase-root {
  height: 56px;
}

.filter-box .MuiFormLabel-root {
  font-size: 16px;
  margin-bottom: 0px !important;
}

.filter-box .label-box {
  margin-left: 15px;
  margin-top: 0px !important;
}

.filter-box .input-box {
  height: 56px;
  background-color: #fff;
  border-radius: 5px;
}


.filter-box .select-sort {
  padding-left: 3px;
  padding-top: -4px;
  margin-top: 0px;
}

.filter-box .select-sort.MuiInput-underline::before {
  border: none !important;
}

.filter-box .select-sort.MuiInput-underline::after {
  border: none !important;
}

.counter_icon_delete {
  z-index: 5 !important;
}

.counter_icon_delete svg {
  right: 0px !important;
}

.file-block {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid green;
}

.file-block p {
  padding-top: 10px;
}

.file-block svg {
  color: red;
}

.item-tickett {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item-tickett span {
  display: flex;
  justify-content: start;
  width: 100%;
  text-align: left;
}

.item-tickett div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  text-align: left;
}
.item-tickett .toggle input:checked+.sliderCounter {
  background-color: #00457C;
}
.log-tickets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}

.log-tickets span {
  font-weight: 600;
  color: #009fe3;
}

.green-btn,
.green-btn:hover {
  background-color: #62ab12;
}

.checkbox-champ {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.checkbox-champ-first {
  padding: 10px 30px;
}

.joEditor::placeholder {
  text-transform: lowercase;
}

.room-type .MuiInputBase-input[disabled] {
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.valueselected-deffect .MuiInputBase-input[disabled] {
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.randomCodePromo {
  cursor: pointer;
  color: #00447b;
}

.randomCodePromo:hover {
  border-bottom: 1px solid #00447b;
}

.dialog {
  display: none;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 999;
}

.show {
  display: flex;
}

.dialogCounter {
  padding: 0px !important;
  overflow-x: hidden !important;
}

.dialogPromocode {
  padding: 0px 15px !important;
  overflow: hidden !important;
}

.dialogPromocode .hrOvwerview:last-child {
  display: none;
}

.dialog-content {
  /* position: relative; */
  /* flex: 1; */
  /* overflow-y: auto;  */
}

.Promocode .close-icon {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 43px;
}

.close-icon svg {
  font-size: 1.7rem !important;
}

.flex-align-room.kaution-option {
  justify-content: start !important;
}

.FieldsAutocomplete {
  flex: 1;
  /* Ensure the FieldsAutocomplete component takes up all available height */
}

.icon-header {
  width: 23px;
  height: 23px;
  margin-right: 1%;
  filter: brightness(0) invert(1);
}

.background-contain-user {
  padding: 1.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background-color: #FFFFFF;
}

.background-contain-customer .MuiAppBar-colorDefault {
  background-color: transparent !important;
}

.background-contain-customer .MuiTabs-root {
  background-color: transparent !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #009fe3 !important;
  background: #95dfff4a !important
}

.MuiTabs-indicator {
  background-color: #009fe3 !important;
}

.MuiTab-root {
  padding: 0px 40px !important;
}

.background-tab {
  margin-top: 0 !important;
  border: 1px solid #9c9c9c;
  background: #f9f9f9;
  padding: 30px 15px;
}

.w-full {
  width: 100% !important;
}


.thm-btn {
  cursor: pointer;
  height: 50px;
  padding: 11px 45px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  text-transform: uppercase !important;
  border: 2px solid #62ab12 !important;
  border-radius: 26px 26px 26px 26px !important;
  background-color: #62ab12 !important;
}

.d-flex-center {
  display: flex !important;
  align-items: center !important;
}

.WeiterButton {
  border-radius: 26px 0px 0px 26px;
  background-color: #62AB12;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: 1px solid #62AB12;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 10px;
  font-weight: 600;
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
}

.WeiterButton.icon-promocode svg {
  width: 30px !important;
  height: 30px !important;
  right: 10px !important;
  top: 12px !important;
}

@media (min-width: 1200px) {
  .search-contain .promocode-search .MuiTextField-root {
    width: 480px !important;
  }
}

input::placeholder {
  color: #717275 !important;
  /* Desired color */
  opacity: 1;
  text-shadow: none !important;
}

.MuiInput-root {
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 7px 8px;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.41);
}

.title-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiSelect-root::placeholder {
  color: red !important
}

.MuiButtonBase-root {
  display: flex !important;
  align-items: center !important;
}


.input_error {
  background: #ff000021;
  border-color: red !important;
}

.input_error.bg-white {
  background-color: #fff !important;
}

.button-disabled {
  opacity: 0.5;
  /* Makes the button look disabled */
  pointer-events: none;
  /* Prevents any click action */
}


.input_error .jodit-container {
  border: 1px solid red !important;
}

.input_error .jodit-wysiwyg {
  background-color: #ff000021;
}

.error-color {
  background-color: #f7c1d3 !important;
  border-left: 10px solid #D00000
}

.RsCustomers {
  background-color: #fff !important;
}

.RsCustomers .nav {
  width: 100%;
}

.RsCustomers .nav-item {
  width: 100%;
}

.RsCustomers .nav-item a {
  padding: 15px 20px;
  border-radius: 0px;
  background: #6c6868 !important;
}

.RsCustomers .table-bordered {
  border: 2px solid #d0cece !important;
}

.RsCustomers .table thead th {
  border-bottom: 2px solid #d0cece;
}

.RsCustomers .table-bordered td,
.table-bordered th {
  border: 2px solid #d0cece;
}

.MuiTab-wrapper {
  font-weight: bold;
}

.RsCustomers .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}

.RsCustomers .table-bordered td,
.table-bordered th {
  border: 2px solid #bcbdbe !important;
  color: #6c6868 !important;
  font-size: 16px;
}

.RsCustomers .table_title {
  padding: 15px 20px;
  border-radius: 0;
  background: #6c6868 !important;
  color: #fff;
  width: 100%;
  font-size: 16px;

}

.premium-container {
  border-left: 10px solid #62ab12;
  background-color: #8bf91338;
  height: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.premium-container span {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.premium-container button {
  border-radius: 26px 26px 26px 26px !important;
  background-color: #62ab12;
  border: none;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
}


/* Styling for the notpremium-container */
.notpremium-container {
  border-left: 10px solid #d00000;
  background-color: #f7c1d3ab !important;
  color: white;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notpremium-container .title {
  color: #333 !important;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.notpremium-container .bemerkung {
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  resize: vertical;
  font-size: 14px;
}

.notpremium-container button {
  border-radius: 26px 26px 26px 26px !important;
  background: red;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}


.erfassung-container {
  color: #717275;
  width: 100%;
}

.erfassung-title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333
}

/* Styling for each section */
.contact-section {
  display: flex;
  flex-direction: column;
}

.input-customer-details {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  height: 50px;
  font-size: 16px;
  color: black;
}

.select-container,
.date-container,
.remark-container {
  flex: 1;
  min-width: 200px;
}

.select-container label,
.date-container label,
.remark-container label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

button {
  font-size: 16px;
}

.erfassung-container select,
input[type="date"],
textarea {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: black;
}

textarea {
  resize: vertical;
  height: 100px;
}

/* Button Styling */
.submit-btn {
  border-radius: 26px 26px 26px 26px !important;
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  display: block;
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.submit-btn:hover {
  background-color: #62ab12;
  color: white;

}

.date-container .MuiInputBase-root {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: black;
  inset: inherit !important;
}

.date-container .MuiInput-underline::before {
  border-bottom: none !important;
}

.date-container .MuiInput-underline::after {
  border-bottom: none !important;
}

.select-container .MuiInput-root {
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  color: black;
  margin-top: 10px;
}

.MuiInput-underline::before {
  border-bottom: 0px !important;
}

.MuiInput-underline::after {
  border-bottom: 0px !important;
}

.appointments-history {
  align-items: center !important;
}

/* Container for the whole appointments history */
.appointments-history-container {
  margin-bottom: 25px;
  background-color: #f4f7fc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



/* Title styling */
.appointments-title {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}


/* Appointment item container with hover effect */
.appointment-item {
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 147 211 / 50%);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect for appointment items */
.appointment-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Styling the individual pieces of information inside each appointment */
.appointment-info {
  font-size: 16px !important;
  color: #333;
  line-height: 1.5;
}

/* Enhance the typography of the date field */
.appointment-info strong {
  color: #009fe3;
  /* Purple color for strong labels */
  font-size: 16px;

}



/* Responsive design - ensure everything looks good on small screens */
@media (max-width: 768px) {
  .appointments-history-container {
    padding: 15px;
  }

  .appointment-item {
    padding: 10px;
  }

  .appointments-title {
    font-size: 20px;
  }
}

.box-shadow-table {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.promocodeAdmin .MuiDataGrid-main{
  padding: 0px 15px !important;
}
.swal-button--cancel {
background-color: red !important;
color:#fff}


.MuiTablePagination-toolbar{
  min-height: auto !important;
}
.MuiTablePagination-spacer{
  display: flex !important;
  align-items: center !important;
}
.MuiTablePagination-selectLabel{
  margin-bottom: 0px !important;
}
.MuiTablePagination-actions{
  display: flex !important;
}
.MuiTablePagination-toolbar{
  align-items: center !important;
}
.MuiTablePagination-root p{
  margin-bottom: 0px !important;
}
.MuiTablePagination-root .MuiSelect-icon{
  margin-right: -0.5rem !important
}
.MuiDataGrid-root{
  overflow: hidden !important;
}
.MuiDataGrid-virtualScroller {
  overflow: hidden !important;
}
.ag-header-cell-text span{
  font-size: 14px !important;
}
.ag-header-cell{
  background-color: #fff !important;
}
.ag-row{
  border-bottom: 1px dashed rgba(151, 151, 151, 1) !important;
}
.ag-theme-alpine .ag-header-cell-resize::after{
 visibility: hidden !important
}
.ag-header-viewport{
  border-bottom: 1px dashed rgba(151, 151, 151, 1);
}
.ag-cell {
  background-color: #fff !important;
}
.ag-header{
  border-bottom: inherit !important;
}
.ag-react-container span{
  font-size: 12px !important;
}
.list-box{
  display: flex;
  justify-content: space-between;
  margin-right: 0.1rem !important;
  gap: 1rem; /* Space between boxes */
}
/* Box styling */
.box {
  position: relative;
  width: 18%; /* To fit 5 boxes in one row */
  background: linear-gradient(146deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
  /*background-color: #00457C;*/
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

/* Icon styling */
.icon {
  position: absolute;
  top: 0px;
  left: 10px;
  font-size: 24px;
}
.icon  .strom-icon  svg{
  fill: white;
}
.icon img {
  filter: grayscale(100%) invert(1) brightness(200%) contrast(100%);
}
/* Title styling */
.title {
  padding: 10px 0px;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

/* Number styling (bottom-right corner) */
.number {
  position: absolute;
  bottom: 0px;
  right: 20px;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

/* Ensure the text and icon are visible and the corners are white */
.box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  pointer-events: none; /* To avoid blocking content inside the box */
}
.fourthPart {
  display: flex;
  max-height: 450px;
}
.FirstBlockAppointment, .SecondBlockAppointment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
/* Button Styling */
.custom-button {
  width: 100%;
  background-color: white;
  color:   rgb(0, 159, 227);
  border: 2px solid   rgb(0, 159, 227);
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.custom-button:hover {
  background: linear-gradient(146deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
  color: white;
}

.custom-button.active:last-child,.custom-button.active:first-child {
  background: linear-gradient(146deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
  color: white;
}

.custom-button:focus {
  outline: none;
}

/* TextArea Styling */
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(0, 159, 227);
  border-radius: 5px;
}

/* Save Button Styling */
.save-button {
  color: white;
  border: none;
  cursor: pointer;
}

.save-button:hover {
  background-color: #007bff;
}

/* Scrollable List */
.scrollable-list {
  overflow-y: auto;
  flex-grow: 1;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
.log-tickets .head-title .title-container,
.accordion-header-rs .head-title .title-container{
  margin-left: 20px;

}
.list-item-icon {
  margin-right: 10px;
}
.list-item-icon svg{
  color: rgb(0,159,227);
}

.list-item-icon .auftrageListIcons{
  width: 30px;
  height: 30px;}

.list-item-text {
  font-size: 14px;
  color: #333;
}
.ag-cell-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-header-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ag-theme-alpine .ag-checkbox-input {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 20px;
}
.ag-theme-alpine .ag-checkbox-input:focus {
  outline: none;
}

.ag-theme-alpine .ag-header-checkbox {
  padding-left: 10px;
}

.ag-theme-alpine .ag-row .ag-cell-checkbox {
  justify-content: flex-start;
  padding-left: 10px;
}
.ag-theme-alpine .ag-checkbox-checked {
  justify-content: center;
}
.ag-header-cell-text{
  font-size: 12px !important;
}
.col-date{
  align-items: center;
  border: 1px solid rgb(0, 159, 227);
  margin-left: 0px !important;
}
.col-date .wohnungsdaten{
  height: 100%;
}
.datetime-picker .MuiFormControl-root{
 margin-top: 10px;
}
.col-date .first_wohnungsdaten{
  border-top-left-radius: initial;
}
.datetime-picker .MuiFormControl-root .MuiInput-root {
  border-width:0px !important;
  border: none !important;
}
.popup-overlay{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-content{
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  border-radius: 8px;
  min-width: 500px;
}
.popup-content h3{    
  margin-bottom: 20px;
}
.popup-content input{    
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid rgb(204, 204, 204);
  }
.popup-footer{
  display: flex;
  justify-content: end;
  margin-top: 32px;
}
.popup-footer .button-popup-save{
  padding: 8px 16px;
  background-color: rgb(76, 175, 80);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  margin-right: 10px;
}
.popup-footer .button-popup-cancel{
  padding: 8px 16px;
    background-color: rgb(244, 67, 54);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 4px;
}
.ag-header-row{
  width: 100% !important;
}
.ag-header-container{
  width: 100% !important;
}
.ag-center-cols-container{
  width: 100% !important;
}
.loadMoreItems{
  position: relative !important;
  top: initial !important;
  right: initial !important;
}