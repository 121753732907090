.outer-container {
	min-height: 150vh;
	margin: 0 30px;
}
.MuiDrawer-docked{
	background-color: #00457c;
}

.card-style {
	width: 100%;
	flex-direction: column;
	display: flex;
	margin: auto;
	align-items: center;
	padding: 20px 0px
}

.datepicker label {
	text-transform: uppercase !important
}

.color-black {
	color: #333 !important;
}

.icon-black {
	filter: brightness(0%) contrast(10000%) saturate(0%) !important;
}

.SonCard {
	width: 100%;
	padding: 10px 0px;
	display: flex;
	flex-direction: column;
}

.cards-row {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #FFFFFF;
	margin: 0px auto !important;
	align-content: center;
	flex-direction: row;
}

.contract-padding {
	padding-left: 30px;
	padding-right: 30px;
}

.cardProtokol {
	height: 260px;
	margin: 20px 5px;
	animation: scl 0.5s ease-in-out;
	transform-origin: left center;
	border-radius: 10px;
	box-shadow: none;
	/* box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.2); */
}

.cardProtokol.card1 {
	background-image: linear-gradient(90deg, rgba(255, 122, 117, 1) 0%, rgb(232, 64 ,58) 100%);

}

.cardProtokol:hover {
	box-shadow: none;
}


.cardProtokol-title {
	opacity: 1;
	color: rgba(255, 255, 255, 1);
	font-family: 'Myriad Pro Regular', sans-serif;
	font-size: 1.2rem;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
}

.contain-card-type {
	display: flex;
	justify-content: space-evenly;
	height: 100%;
	align-items: center;
	flex-direction: column;
}

.cardProtokol1-desc {
	text-align: center;
}

.cardProtokol2-desc {
	text-align: center;
}

.cardProtokol3-desc {
	text-align: center;
}

.cardProtokol button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	cursor: pointer;
	width: 80%;
	padding: 10px 6px;
	border: none;
	margin: 0 auto;
	border-radius: 25.5px;
	border: 1px solid rgba(255, 255, 255, 1);
	opacity: 1;
	background-color: rgba(98, 171, 18, 1);
	color: rgba(255, 255, 255, 1);
	font-family: 'Myriad Pro Regular', sans-serif;
	font-size: 1rem;
	font-weight: 600;
	font-style: normal;
	letter-spacing: 0px;
	text-align: center;
	text-transform: uppercase;

}

.text {
	flex: 1;
	text-align: center;
}

.icon-cardProtokol {
	position: relative;
	width: 16px;
	height: 16px;
	color: white;
}

.cardProtokol .active button {
	padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
	border: 1px solid rgba(98, 171, 18, 1);
	color: rgba(98, 171, 18, 1);
	background-color: rgba(255, 255, 255, 1);
}

.cardProtokol svg{
	margin-left: 10px ;

}

.cardProtokol .active SVG {
	color: rgba(98, 171, 18, 1);
}

.EinzugHR {
	margin: 0 auto;
	width: 85%;
	border: none;
	height: 1px;
	background: black;
	opacity: 0.2;
}

.EinzugHR-B {
	margin: 0 auto;
	width: 85%;
	border: none;
	height: 1px;
	background: white;
	opacity: 0.5;
}

.cardProtokol.card2 {
	background-image: linear-gradient(270deg, rgba(99 ,47, 200) 0%, rgba(161, 116, 249, 1) 100%);
}


.cardProtokol.card3 {
	background-image: linear-gradient(90deg, rgba(12, 177, 170, 1) 0%, rgba(10, 151 ,145) 100%, rgba(6, 101, 95, 1) 100%);
}

.NewlocIcon {

	width: 6rem;
	height: 6rem;
	filter: brightness(0) invert(1);
}

.OldlocIcon {

	width: 6rem;
	height: 6rem;
	filter: brightness(0) invert(1);
}

.NewOldLocIcon {

	width: 6rem;
	height: 6rem;
	filter: brightness(0) invert(1);
}

.cardProtokol :hover {
	box-shadow: 7px 7px 15px 2px transparentize(black, 0.5);
	transition: box-shadow 0.3s ease-in;
}

.protocol.cards-row{
	padding-left: 80px;
	padding-right: 80px;
	border-top-left-radius: 5px;
    margin-top: -3px !important;
	padding-top: 0px !important;
}
.neuer.table-design{
	border-top-right-radius: 0px !important;
}
.table-design-last-part{
	cursor: pointer;
	border-top-right-radius: 8px;
	background-color:#F4BF55 ;
	display: flex;
	align-items: center;
	justify-content: center;
}
.neuer {
	border-radius: 8px 8px 0px 0px;
    opacity: 1;
    background-image: linear-gradient(146deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
	padding: 14px 30px;
	height: 60px;
	display: flex;
	color: var(--white);
	align-items: center;
	font-family: 'Myriad Pro Regular', sans-serif;
	
	text-transform: uppercase;
}

.neuer h3 {
	font-family: 'Myriad Pro Regular', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	margin-left: 15px;
	margin-bottom: 0;
	text-transform: uppercase; /*capitalize !important;*/
}

.neuer h3::first-letter {
	text-transform: uppercase;
	/* Capitalize the first letter */
}

.NeuerIcon {

	filter: brightness(0) invert(1);
	width: 24px;
    height: 30px;
}

.after-cards {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #FFFFFF;
}
.geben.padding{
	padding: 0px 80px !important;
 
}
.geben {
	padding: 0px 40px;
	background: white;
	color: rgba(0, 159, 227, 1);
	font-family: 'Myriad Pro Regular', sans-serif;
	font-size: 1.2rem;
	font-weight: 700;
	font-style: normal;
	line-height: 24px;
	width: 100%;
}

.contract-padding {
	padding-top: 0px !important;
}

.textfieldsdateplz {

	display: flex;

	background-color: #FFFFFF;
	margin-top: 1rem;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	padding-left: 40px;
	flex-direction: row;
	flex-wrap: nowrap;
}



.plz {
	padding: 0px 60px 0px 10px;
}

.plz .css-13aprtc-MuiFormControl-root-MuiTextField-root {
	width: 100%;
}

.w-half {
	width: 50%;
}

.weiter-protocolltype button {
	border-radius: 26px 26px 26px 26px;
	background-color: #62AB12;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 10px 15px;
	text-decoration: none;
	border: 1px solid #62AB12;
	cursor: pointer;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	font-family: 'Myriad Pro Regular', sans-serif;
}



.icon-buttonweiterPT {
	vertical-align: middle;
	width: 20px;
	height: 20px;
	fill: #fff;
}

.calanderIcon {
	position: absolute;
	top: 59%;
	left: 6%;
}


#calander-label {
	padding-left: 10px;

}

#calander-label.MuiInputLabel-shrink {
	padding-left: 0px !important;
}

#calander-label label.Mui-focused {
	padding-left: 0px !important;
}

#calander {
	padding-left: 10px;
	overflow: hidden;

}

.datepicker .MuiFormControl-root {
	width: 100%;
}
.ticket-autocomplete .MuiAutocomplete-hasPopupIcon{
	background-color: #fff !important;
}