* {
  box-sizing: border-box;
  font-family: var(--heading-font);
}

.dashboard-test {
  position: relative;
  height: 150vh;
  left: 290px;
  top: 216px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 12px;
  background-color: aliceblue;
}

.AuftrageIcon {
  /* width: 2.5%;
  height: 2.5%; */
  width: 40px;
  height: 40px;
  margin-right: 1%;
  filter: brightness(0) invert(1);
}

.AuftrageListIcon {
  width: 1.75rem;
  height: 2.25rem;
  margin-right: -2%;
  object-fit: cover;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.no-header .MuiDataGrid-columnHeader,
.no-header .MuiDataGrid-rowHeader {
  display: none;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: space-between;
}
/* .css-cp5hn7, */
/* .MuiDataGrid-root .css-ltf0zy-MuiDataGrid-iconButtonContainer {
  visibility: visible !important;
  justify-content: end;
  width: 100% !important;
} */
/* .css-cp5hn7, */
/* .MuiDataGrid-root .MuiDataGrid-sortIcon {
  opacity: 1 !important;
} */
.auftrageListIcons {
  width: 1.5rem;
  height: 1.5rem;
  filter: invert(50%) sepia(63%) saturate(5633%) hue-rotate(170deg) brightness(99%) contrast(101%);
}

.auftrageCalendarIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 15%;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%);
}

.search-bar {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  color: #999;
}

/* input[type='text'] {
  padding-left: 2.5rem;
} */

.search-bar-field {
  background-color: #fff;
  border-radius: 10px;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}

.search-padding #auftrage_search-bar {
  color: #000000;
  font-family: var(--heading-font);
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  padding: 14px 0px !important;
}
#auftrage_search-bar {
  color: #000000;
  font-family: var(--heading-font);
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  padding: 14px !important;
}
 .icon-close svg {
  right: -2px !important;
}
.divSearchBar {
  display: flex;
  align-items: flex-end;
  padding: 15px 40px;
}
.divSearchBarExport {
  display: flex;
  align-items: flex-end;
  margin-left: 7px;
  margin-top: 10px;
}
.search-contain {
  display: flex;
  justify-content: end;
}

.search-dialog {
  display: flex;
  justify-content: center;
}

.dialog_real_state .titleZahlerList {
  align-items: center !important;
  justify-content: end !important;
}

.dialog_real_state .titleZahlerList svg {
  margin-top: 15px !important;
  position: relative !important;
    top: inherit;
    right: inherit;
}

.dialog_real_state .BestatigenButton button {
  width: 334px !important;}
.dialog_real_state .BestatigenButton button svg{
  top:14
}

.dialog_real_state .MuiDialogActions-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog_real_state .list-container {
  padding: 15px 20px;
}

.dialog_real_state ul {
  list-style-type: none;
  padding: 0;
}

.dialog_real_state .auftrageListIcons {
  filter: none !important;
}

.dialog_real_state .liegenschaftListIcons {
  filter: brightness(0%) !important;
}

.icons-row .span-style {
  font-size: 1rem;
  font-weight: 600;
}

.dialog_real_state li {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px dashed rgba(151, 151, 151, 1);
}

.dialog_real_state li .custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  margin-right: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog_real_state li .custom-checkbox {
  margin-bottom: 0px !important;
}


.dialog_real_state .item-content {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dialog_real_state .icons-row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.dialog_real_state .icons-row>div {
  display: flex;
  align-items: center;
}

.dialog_real_state .icons-row span {
  margin-top: 2px;
}

.dialog_real_state li .custom-checkbox input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dialog_real_state li .custom-checkbox .checkmark {
  position: absolute;
  display: none;
}

.dialog_real_state li .custom-checkbox input[type="checkbox"]:checked~.checkmark {
  display: block;
}

li .custom-checkbox .checkmark i {
  color: #333;
}

.dialog_real_state li span .icon-build {
  margin-right: 10px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog_real_state li span {
  font-size: 1rem;
}

.dialog_real_state li input[type="checkbox"] {
  margin-left: auto;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
}

.search-padding {
  padding: 15px 40px;
}

.layoutstepsbar.height-auto {
  height: auto;
}

.no-data-auftrage {
  font-size: 18px;
  height: 100px;
  line-height: 100px;
  color: #333;
  text-align: center;
}

.my-label {
  text-transform: uppercase !important;
  padding: 0 5% 0 0;
  background-color: white;


}

.dashRealEstate {
  font-family: 'Myriad Pro Regular', sans-serif;
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.dashRealEstateButton {
  border-radius: 26px 0px 0px 26px;
  background-color: #62AB12;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: 1px solid #62AB12;
  cursor: pointer;
  text-transform: uppercase;
  padding: 8px 10px ;
  font-weight: 600;
}
.input-counterInfo{
  display: flex;
    align-items: center;
}
.input-counterInfo label{
  margin-bottom: 0px;
}
.input-counterInfo span{
  margin: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiSelect-icon {
  font-size: 2rem !important;
  margin-right: 0.5rem;
  margin-top: -0.2rem;

}
.DialogDuplicate .icon-close-Dialog-Duplicate svg {
  position: absolute !important;
  right: 14px;
  top: -25px;
}
.search-padding .MuiTextField-root{
  width: 460px;
}
@media (min-width: 768px) { /* md */
  .search-contain .search-padding .MuiTextField-root {
    width: 590px; 
  }
}
@media (min-width: 961px) { /* md */
  .search-contain .search-padding .MuiTextField-root {
    width: 490px; 
  }
}
@media (min-width: 1200px) { /* md */
  .search-contain .search-padding .MuiTextField-root {
    width: 700px; 
  }
}
@media (min-width: 1561px) { /* md */
  .search-contain .search-padding .MuiTextField-root {
    width: 790px; 
  }
}