
.RoomsinfoIcon {
    margin-top: 15px;
    width: 40px;
    margin-left: -725px;
    filter: invert(1);
}
.RaumDetailsIcon {
    width: 40px;
    height: 40px;
    margin-right: 30px;
    filter: brightness(0) invert(1);
  }
.RoomCameraIcon {
  display: flex !important;
}
.flex-align-room{
    display: flex;
    align-items: center;
    justify-content: center;
  }
.delete-col{
    padding: 15px 0px;
}
.delete-col button{
    display: flex;
    margin: 0 auto;
}
.LigneStatus {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.div-status {
    opacity: 1;
    font-family: 'Myriad Pro Regular', sans-serif;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
}
.status-title {
    color: #009FE3;
}

.normal-status {
    color: gray;
    margin-left: 9px;
}

.normal-status SVG {
  margin-bottom: 22px;
}

.ok-status {
    color: #62AB12;
    margin-left: 9px;
}

.badIcon {
    filter: blur();
}
.bad-status {
    color: #D00000;
    margin-left: 9px;
}


.div-emotion-button{
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.ThumbUp-button {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 175px;
    height: 55px;
    border-radius: 9.5px;
    border: 0.5px solid #FFFFFF;    
    opacity: 1;
    color: #000000;
    background-color: rgba(255,255,255,1);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.ThumbUp-buttonProtokolControl {
  display: flex;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  width: 175px;
  height: 55px;
  border-radius: 9.5px;
  border: 0.5px solid #888888;
  opacity: 1;
  background-color: #888888;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.OK .ThumbUp-button {
    color: rgba(255,255,255,1);
    background-color: #62AB12;
    border: 0.5px solid #62AB12   
}

.OK .ThumbUp-buttonProtokolControl {
  color: rgba(255, 255, 255, 1);
  background-color: #62AB12;
  border: 0.5px solid #62AB12
}

.OK-emoji{
    margin-right: 9px;
}

.ThumbDown-button {
    color: #000000;
    background-color: #FFFFFF;
    border: 0.5px solid #FFFFFF;  
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 175px;
    height: 55px;
    border-radius: 9.5px;
    opacity: 1;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.ThumbDown-buttonProtokolControl {
  color: #FFFFFF;
  background-color: #888888;
  border: 0.5px solid #888888;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  width: 175px;
  height: 55px;
  border-radius: 9.5px;
  opacity: 1;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  text-transform: none;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.mangel-emoji{
    margin-right: 9px;
}

.BAD .ThumbDown-button {
    color: #FFFFFF;
    background-color: #D00000;
    border: 1px solid #D00000;  
}

.BAD .ThumbDown-buttonProtokolControl {
  color: #FFFFFF;
  background-color: #D00000;
  border: 1px solid #D00000;

}

.SonCard .hidden {
    display: none;
}

.BeschreibungIcon {
    width: 40px;
    height: 40px;
    color: #D00000;
    margin-right: -40px;
    margin-top: 11px;
}

#Beschreibung-label{
    padding-left: 7%;
    color: #D00000;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
  }
  
#Beschreibung-label.MuiInputLabel-shrink {
    padding-left: 0%;
  }
  
#Beschreibung {
    padding-left: 11%;
  }

.LBeschreibung{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.LButtonsBeschreibung{
    display: flex;
    justify-content: end;
}

.Hunzufugen-button {
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 270px;
    height: 65px;
    border-radius: 35.5px;
    border: 0.5px solid #62AB12;
    opacity: 1;
    color: #FFFFFF;
    background-color: #62AB12;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 2%;

}
.BeschreibungDIV  label{
    padding-left: 1.5rem !important;
  }
.BeschreibungDIV2 label {
      margin-left: 0rem !important;
      background-color: #FFFFFF;
    }
.BeschreibungDIV label.Mui-focused {
  background-color: #fff;
  padding: 0rem;
  margin-left: -0.5rem;
}
  #room_deffect_notice{
    padding-left: 2.5rem !important;
  }
  .col-mdr-1-5 {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .col-mdr-4 {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
  .col-mdr-1-5 {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  
  .col-mdr-1 {
    flex-basis: 8.33%;
    max-width: 8.33%;
  }
  .Loschen-button svg{
    bottom: inherit !important;
    top:5px !important
  }
  .image-right .icon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1.5rem;
    z-index: 1;
    padding: 5px;
    align-items: center;
    display: flex;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    background: #009fe3;
}
.Loschen-button {
     position: relative !important;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    width: 270px;
    border-radius: 35.5px;
    border: 0.5px solid #D00000;
    opacity: 1;
    color: #FFFFFF;
    background-color: #D00000;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 15px 0 rgba(0, 0, 0, 0.09);
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.LoschenBasketIcon {
    width: 45px;
}
.iconObject{
  position: relative !important;
  right: inherit;
  top:inherit
}
.RBeschreibung{
    border-left: 15px solid #D00000;
    border-top: 3px solid #D00000;
    border-bottom: 3px solid #D00000;
    border-right: 3px solid #D00000;
    padding: 20px;
}

.cardMangel {
  
  background-color: #e5e4e4;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;}

.card-content {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.room-deffect-contain  .image-overlay img{
  height: 100%;
}
.card-imageMangel {
  width: 100%;
  height: max-content;
  height: 200px;
  max-height: 200px;
  object-fit: cover;
}

.card-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.card-details .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: inherit;
}



.centerOneImage {
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.image--container {
  position: relative;
  display: inline-block;
}
.image--container.right{
  right: 0px !important;
}

.image--container .icon {
  
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5rem;
  z-index: 1;
  padding: 5px;
  align-items: center;
  display: flex;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  background: #009fe3;

}
.image-object{
  width: 100%;
  height:250px ;
  object-fit: cover;
}
.image--container {
  right: 10px !important;
}

.image--container .icon.overviewR {
  right: 4px !important;
}
.image--container.image-right{
 right:inherit !important
}
.image--container button{
  position: initial !important;
 }
.weiter-button-alig{
  display: flex;
  align-items: center;
  justify-content: end;
}

.image--container .image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.image-right .image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.image--container .image {
  max-width: 100%;
  max-height: 100%;
}
.first_wohnungsdaten-1{
  display: flex;
  color: var(--white);
  background: linear-gradient(316deg, rgba(0, 159, 227, 1) 0%, rgba(2, 110, 156, 1) 100%);
  flex-direction: column;
  padding: 5px 30px ;
  border-top-right-radius: 5px;
  border-top-left-radius: 0;
}
.first_wohnungsdaten-1 .vl h3 {color: #ffff;}
.first_wohnungsdaten-1 .vl {
  border-left: 2px dashed #ffff;
}